<template>
  <div style="width: 100%">
    <h1>
      <span>Корисничко упутство</span>
    </h1>
    <p>
      <span
        >Са развојем технологије рапидно се мења и свет око нас. Виртуелна
        стварност игра у томе велику улогу и мења будућност учења и слику
        образовања на коју смо навикли. Корисници могу бити укључени у сценарија
        у виртуелном окружењу која ће их припремити за проблеме са којима ће се
        касније сусретати у стварном животу. Када је реч о саобраћају, потребно
        је само приступити страници Обазривка путем адресе:
        <a href="https://obazrivko.com">https://obazrivko.com</a>.</span
      >
    </p>
    <p>
      <span
        >Све опције Обазривка подељене су у пет главних менија доступних на
        уређајима опремљеним жироскопом, док је на рачунарима доступно само
        четири (није доступан VR модул)</span
      >
    </p>
    <p>
      <img
        src="https://lh4.googleusercontent.com/p7kcsa0YfNCzDovvhBBl7ksMuZKts1FLr2q8IAbGh-kA0M55uxoHwyeP2MGYnMW85UuZgOmzmRVSBaBMYFQByEFhfwIX34TMqpiB6qvpTuYlJo7TTDpSah-1mEdfSqi7PhnD2QY"
        class="guide-img"
      />
    </p>
    <h1 style="line-height: 1.295; margin-top: 12pt; margin-bottom: 0pt">
      <span>VR МОДУЛ</span>
    </h1>
    <p>
      <span
        >За разлику од 360 &deg; приказ модула када се покрене VR модул екран се
        аутоматски дели на два дела. Лева страна је дизајнирана за лево око, а
        десна за десно. Уколико корисник има VR наочаре може користити овај
        модул, а у супротном користи 360 &deg; приказ. Осим корисничког
        искуства, нема функционалних разлика у односу на 360 &deg; приказ
        модула.</span
      >
    </p>
    <p>
      <span
        ><span
          ><img
            src="https://lh6.googleusercontent.com/gtYKMijXXd9ALAGNQwn4HYl-p3XjWk7KmZ8f8FnajE5KW76pM8p5VBIb-8bJRuwXK4mZwul6lqP2n6gaCbyCz9cGuBtCJ-MKyNU17n3ncRrqUiPsPCcX15T-p5HDKDdV6sWWFl4"
            class="guide-img" /></span
      ></span>
    </p>
    <h1 style="line-height: 1.295; margin-top: 12pt; margin-bottom: 0pt">
      <span>360 &deg; приказ</span>
    </h1>
    <p>
      <span>Интерактивност &ndash; кључни елемент за виртуелно искуство.</span>
    </p>
    <p>
      <span
        >Објекти са са којима корисник остварује интеракцију могу се поделити у
        три категорије, у зависности од врсте питања:</span
      >
    </p>
    <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
      <li>
        <div>
          <p>Редослед проласка кроз раскрсницу</p>
          <span
            ><span
              ><img
                src="https://lh3.googleusercontent.com/uKEOJVzOF-uZf_dlAT0RsqaWzB7_lxTYUW2cafp1GROpJ3k0PEYuvUmaXew3DjIigaqwpIQaI4EBX7CvFxEoRd1omc5YQGawv5VHfYIi5qmKSRhbGHFFfkK-aIWqaK7iwv9UBgs"
                width="48"
                height="52" /></span
          ></span>
        </div>
      </li>
      <li>
        <div>
          <p>Постави одговарајући саобраћајни знак</p>
          <span
            ><span
              ><img
                src="https://lh3.googleusercontent.com/FrpRd41EE7R9-7fGIj8aTe_1lgjzQG0EtcxO2WeZ9UoTf97192qsJVCgWvAsd5Q9rTtpVYdAoyHMnwRKVT7bynYrFcuuZy7nKzYuyWju1fwEAXBTNCl63R9zzyo5LonifCNqPjM"
                width="53"
                height="47" /></span
          ></span>
        </div>
      </li>
      <li>
        <div>
          <p>Тачно или нетачно</p>
          <span
            ><span
              ><img
                src="https://lh3.googleusercontent.com/auet4TYkgIwaieUpT4voEC4JwSgf_MUbBn3B2hUkjjXstVXPMr3p4t971ExmnhQFYOEjcc7_TG_iscJgIf7NIHaD6NeKtYnFI2Jm1ep8ScmbJ4Wb7avs1WHBB4txnw5uaDO_yfA"
                width="44"
                height="41" /></span
          ></span>
        </div>
      </li>
    </ul>
    <p>
      <span
        >Кликом на објекат добија се нови објекат из скупа предефинисаних.</span
      >
    </p>
    <p>
      <span
        >Са сваким новим кликом на приказани објекат, добија се нови, чије се
        одабир врши из скупа предефинисаних објеката, али на случајан
        начин.</span
      >
    </p>
    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 2pt">
      <span
        >Када корисник одабере жељене објекте кликом на Даље потврђује да је
        селектовао жељене одговоре.</span
      >
    </p>
    <br />

    <h2>Редослед проласка кроз раскрсницу</h2>

    <p>
      <span
        ><span
          ><img
            src="https://lh3.googleusercontent.com/vx_jpBDu-xWseddcETNW7lu6-L4Wo--PrMnKut62xmMt8nYzcLyD5sS3vU3EJZtEFNYPlo1N1aiMeIdxMQ2DaJG_ifCBXh5zRuy2fnxedZcCACzi_7S9XFBRELVbLNB9FzZIDoc"
            class="guide-img" /></span
      ></span>
    </p>
    <p>
      <span
        ><span
          ><img
            src="https://lh5.googleusercontent.com/JgllJ6qD7oYmhagfO975dJQgCpJADUd2GOFFsHY23yuO39OuUOcJe2MmHaQ93s9QnsErr2piAItk1-G-JcMT0vJ5UsNtTQTCdWu8__CZKeQoygeWlWqwJIiI1b-1M6yDJch9LPQ"
            class="guide-img" /></span
      ></span>
    </p>
    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 2pt">
      <span
        ><span
          ><img
            src="https://lh3.googleusercontent.com/S4OAaaSzi9L3Ge9TLwadqQWDOlX3Q3-E7mZtr0Dpmtc8tYcFxLdL9lRDP4OkMFBraW1MY7P-42lG10oZZjsa_-LKMXI9ywwRYvJCToE6uIOCu0a5i66k4O2iEjN6KdXWP95UkPc"
            class="guide-img" /></span
      ></span>
    </p>
    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 2pt">
      <span
        ><span
          ><img
            src="https://lh5.googleusercontent.com/Qy0uZqJot6Uu_lhM5mlTHYx-5QJ_OPbqHqHAPLPoKDbJW5U1n2eVr0FOd39rqdZgLQYkHOHrDpgWLT6EMIKPwHwAYzjTi1GZXfNunLUSRXS3hwagbk_MdNE1p6qIJdS7CT5QaHM"
            class="guide-img" /></span
      ></span>
    </p>
    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 2pt">
      <span
        ><span
          ><img
            src="https://lh6.googleusercontent.com/Rw-R-7lTijivccGq9wpqXm_FVf8sW-NEiSOBmkjbWl4zbASgg6Zuw54Qsp13zK-1RzRyTaKOnIvMMcnMD7mzD5T20Q8fdZ4dzCfW86exZUKUGz9lYOrqywKrY1rB2snCpNDjeRQ"
            class="guide-img" /></span
      ></span>
    </p>

    <h2>Постави одговарајући саобраћајни знак</h2>

    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 2pt">
      <span
        ><span
          ><img
            src="https://lh6.googleusercontent.com/amW5qhxKJvb7ze64wA_KS3UuIQQx15r1n9VBTvni00hoFlerHFOvr-ll5kGEPEHb6A56P38YAeJU6y0gy4GCWhO9hv3EVdZhEUuktQX_yvCkeSv4LWuYSudkUMgUzm9eAwgGP4A"
            class="guide-img" /></span
      ></span>
    </p>
    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 2pt">
      <span
        ><span
          ><img
            src="https://lh3.googleusercontent.com/NXxDWzXzD-61uUJ8BOITF1X0LoJWL0KwDQC1CgxY-607y5SKDMNxVLHChS67ryoBzbgt9bLLXK2AAwy2BALjl_PZ7sFVC5HEbi0BG0L-BnJfPVT8qwMy_4EQjJZWSUWszA4Jgww"
            class="guide-img" /></span
      ></span>
    </p>
    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 2pt">
      <span
        ><span
          ><img
            src="https://lh6.googleusercontent.com/TLpYwHAO8u1TT0kNZWGe0zcfU4uq1NqJuGav-wJ6EmscIwccUxwU0RTSqmNkJEcwm92Ac3N81nuO_pzQpqiF-CqOWQg-aeC-86V5DnTn8GHGiHHJLysqs7tcGhbIfwd1Fru2x0I"
            class="guide-img" /></span
      ></span>
    </p>
    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 2pt">
      <span
        ><span
          ><img
            src="https://lh4.googleusercontent.com/lHgy9OH4gf9K0LAT3-BNP9hKjwD8wVPuW15PEMdXxbwhjqdxbX5l2PEvH9rwvOTjaCT5Gztqe_wtYcSAxpQCj_X9PcVaEy_YZA-UMqPVXk1CdDqyZif-KIha55z3fLfrnF7ew1U"
            class="guide-img" /></span
      ></span>
    </p>
    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 2pt">
      <span
        ><span
          ><img
            src="https://lh4.googleusercontent.com/KSFMWW10uehDKndswndbuEE5TTMC11kVnzxn_t2b9vs7Htow9W2nTzWgvgknwZs7GqbIeCoLX_3WrVkPPbTZ9n_iWbwzTEMxcEPB1QpKFmMA0kyhJn84d3Y3Rmgyef8SP0hKDIE"
            class="guide-img" /></span
      ></span>
    </p>
    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 2pt">
      <span
        ><span
          ><img
            src="https://lh4.googleusercontent.com/am6vGbZEuCmzcwQ9uc_PnbFu5yqRch5XZ_beXZHXfrHOx6cBDc7YJ-zzFwX1Dlny91dXtbEU1GnjyNTHmfsp-qBhsKBnq9mVjv8bqxuxwZffk0CyQK6dJ_NkPEqFX_4AQ-ZPSdo"
            class="guide-img" /></span
      ></span>
    </p>
    <p><br /></p>

    <h2>Тачно или нетачно</h2>

    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 2pt">
      <span
        ><span
          ><img
            src="https://lh3.googleusercontent.com/hbJxme2wcVRjWQ9zNu95CHyukcYGSEgbPxPzmb0zaWD0zrodGvLejp7jo3rWXGUKLEJK2_pTg5mWIgi5WopbZg5_UyxhU7zWPUY15xTlbn_efBm5Nx5KCR4gagFjb5I_8cnDoQg"
            class="guide-img" /></span
      ></span>
    </p>
    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 2pt">
      <span
        ><span
          ><img
            src="https://lh5.googleusercontent.com/ghyxG_MGoiM87awwW8VIXSD2lkuv14JrtANMDLOcx2eG8a2-8ewCVQuok1xuu3wX9FOOf0NdAHGAsDeN4wtrjF9H6rVf_lFBJGwdMvgUlzokqZx0s8iOQCxB6HCnrpnQ95603kU"
            class="guide-img" /></span
      ></span>
    </p>
    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 2pt">
      <span
        ><span
          ><img
            src="https://lh3.googleusercontent.com/l_XqLNJaM021H_9PVegTUkHTMRMnRbSsB59cqPmLhtaHDjR1-4_tR64op7_sAYk3Y4-3AKAId1evS1PELN3B5s_tOWD3YHIjYozheGrqkVfDEc4j5jEO6i7QqGQf8a67E5PVZfA"
            class="guide-img" /></span
      ></span>
    </p>
    <h1 style="line-height: 1.295; margin-top: 12pt; margin-bottom: 0pt">
      <span>Тестови</span>
    </h1>
    <p>
      <span
        >Тестови се састоје од питања типа Тачно/Нетачно. Питања покривају две
        теме: познавање значења саобраћајних знакова и решавање разних
        саобраћајних ситуација.</span
      >
    </p>
    <p>
      <span
        ><span
          style="
            border: none;
            display: inline-block;
            overflow: hidden;
            width: 652px;
            height: 278px;
          "
          ><img
            alt="C:\Users\Nemanja Spalevic\AppData\Local\Microsoft\Windows\INetCache\Content.Word\6.jpg"
            src="https://lh6.googleusercontent.com/2Te8TX7n-V30RcUlDxoBng7kyLHTFstXTEYTH4U5ff1wcY7fOv2v8HJZHJvhUGxWJNNetxjdGTmdQ6rxqUXqs7z3P9ot8cbD_jOiuwycTCMPHG_ucvLQWD8A-cb3pe5_ig1FHgE"
            class="guide-img" /></span
      ></span>
    </p>
    <p>
      <span
        ><span
          ><img
            src="https://lh6.googleusercontent.com/Yuo1eFI7Luc3PE9DMH4zZFesLg9p3DBY83u4GhSx3dXENTP-tlY6NQURZuRAe58o2B2YiuldbLy8iw3xssCsmRHKFH7e9OJhJFPMTqGgKN7Y9fA-OzsEadSc15zu1esEsfzZ5_g"
            class="guide-img" /></span
      ></span>
    </p>

    <h1 style="line-height: 1.295; margin-top: 12pt; margin-bottom: 0pt">
      <span>Знакови</span>
    </h1>
    <p>
      <span
        >Питања се односе на познавање значења саобраћајних знакова. Након
        потврде одговора кориснику је понуђена могућност да одбаци питање. На
        овај начин корисник сужава скуп питања из кога на случајан начин добија
        свако наредно питање.&nbsp;</span
      >
    </p>
    <p>
      <span>
        <span>
          <img
            src="https://lh5.googleusercontent.com/M5SVKPHpOXsPmTUJAdjyqvQwKnSeOl-J4_dVAzJFqe7-T2McncCfW6IdP0_BJeArnx4l3YWd3_y1qul-DoADjebML1K-VNQVzxyKwJL8HvgLby_3AUfQ2m-uJmcsKufhNXiIIW8"
            class="guide-img" /></span
      ></span>
    </p>
    <p>
      <span
        ><span
          ><img
            src="https://lh5.googleusercontent.com/ckMtXWSvjwTcBifX4ctkyQjKUXS3MerBMhJmXGgKFg54EjxBg7GeZxcu5skPydmUnCMr4BCAXMRbdozg9JDzYJ8Ocs8m9Js016qs_iNHnZhRqd31zmeYN-taD5vQzPstfgKnDFg"
            class="guide-img" /></span
      ></span>
    </p>
    <h1 style="line-height: 1.295; margin-top: 12pt; margin-bottom: 0pt">
      <span>Више</span>
    </h1>
    <p>
      <span>Мени Више садржи линкове за приступ корисном материјалу.</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "application-guide",
};
</script>

<style>
.guide-img {
  max-width: 100%;
}
</style>